import { tsParticles } from "tsparticles-engine";
import { loadSlim } from "tsparticles-slim";
import './OverlayScrollbars';

var jquery = require("jquery");
window.$ = window.jQuery = jquery;

const updateCopyrightText = () => {
  const currentYear = new Date().getFullYear();
  $('.copyright').text(`© ${currentYear} Aiperion LLC. All rights reserved.`)
};

$(document).ready(() => {
  OverlayScrollbars(document.querySelectorAll("body"), {
  });
  updateCopyrightText();
});

loadSlim(tsParticles).then(() => {
  tsParticles.load('home-particles', {
    fps_limit: 18,
    particles: {
      number: {
        value: 100,
        density: {
          enable: true,
          value_area: 800
        }
      },
      line_linked: {
        enable: true,
        distance: 40,
        opacity: 0.5,
        width: 0.1
      },
      move: {
        enable: true,
        direction: 'right',
        straight: true,
        speed: 0.4,
        out_mode: 'out'
      },
      size: {
        value: 2,
        random: true,
        anim: {
          enable: true,
          speed: 2,
          size_min: 0.1
        }
      },
      opacity: {
        value: 0.5,
        random: true,
        anim: {
          enable: true,
          speed: 0.5,
          opacity_min: 0.1
        }
      }
    },
    retina_detect: true
  }).then(container => {
    // console.log("callback - tsparticles config loaded", container);
  }).catch(error => {
      console.error(error);
  });

  if (document.getElementById("purpose-particles")){
    tsParticles.load('purpose-particles', {
      fps_limit: 18,
      particles: {
        number: {
          value: 100,
          density: {
            enable: true,
            value_area: 800
          }
        },
        line_linked: {
          enable: true,
          distance: 40,
          opacity: 0.5,
          width: 0.1
        },
        move: {
          enable: true,
          direction: 'right',
          straight: true,
          speed: 0.4,
          out_mode: 'out'
        },
        size: {
          value: 2,
          random: true,
          anim: {
            enable: true,
            speed: 2,
            size_min: 0.1
          }
        },
        opacity: {
          value: 0.5,
          random: true,
          anim: {
            enable: true,
            speed: 0.5,
            opacity_min: 0.1
          }
        }
      },
      retina_detect: true
    }).then(container => {
      // console.log("callback - tsparticles config loaded", container);
    }).catch(error => {
      console.error(error);
    });
  }

  if (document.getElementById("about-brand-particles")){
    tsParticles.load('about-brand-particles', {
      fps_limit: 18,
      particles: {
        number: {
          value: 100,
          density: {
            enable: true,
            value_area: 800
          }
        },
        line_linked: {
          enable: true,
          distance: 40,
          opacity: 0.5,
          width: 0.1
        },
        move: {
          enable: true,
          direction: 'right',
          straight: true,
          speed: 0.4,
          out_mode: 'out'
        },
        size: {
          value: 2,
          random: true,
          anim: {
            enable: true,
            speed: 2,
            size_min: 0.1
          }
        },
        opacity: {
          value: 0.5,
          random: true,
          anim: {
            enable: true,
            speed: 0.5,
            opacity_min: 0.1
          }
        }
      },
      retina_detect: true
    }).then(container => {
      // console.log("callback - tsparticles config loaded", container);
    }).catch(error => {
      console.error(error);
    });
  }
});

export const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
